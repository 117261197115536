<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>예약 관리</h2>
    </div>

    <div class="box one planner">
      <div>
        <el-select
          v-model="provider"
          placeholder="선택"
          class="searchCondition"
          @change="getScheduleList"
        >
          <el-option key="전체" label="전체" value=""> </el-option>
          <el-option
            v-for="data in providerList"
            :key="data._id"
            :label="data.providerName"
            :value="data.providerName"
          >
          </el-option>
        </el-select>
        <router-link to="/admin/schedule/register" class="flr mb8">
          <button class="basic">스케줄 등록</button>
        </router-link>
      </div>
      <div class="calendar">
        <vc-calendar
          class="custom-calendar max-w-full"
          :attributes="attributes"
          disable-page-swipe
          is-expanded
        >
          <template v-slot:day-content="{ day, attributes }">
            <div
              class="flex flex-col h-full z-10 overflow-hidden"
              @click="handleDate(day)"
            >
              <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <p
                  v-for="attr in attributes"
                  :key="attr.key"
                  class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                  :class="attr.customData.status"
                >
                  <el-tag v-if="attr.customData.status == 'confirm'" size="mini"
                    >확정</el-tag
                  >
                  <el-tag
                    v-else-if="attr.customData.status == 'cancel'"
                    type="danger"
                    size="mini"
                    >취소</el-tag
                  >
                  <el-tag v-else type="warning" size="mini">대기</el-tag>
                  {{ attr.customData.title }}
                </p>
              </div>
            </div>
          </template>
        </vc-calendar>
      </div>
    </div>
    <div class="box one filter">
      <div class="plannerBox">
        <div class="schedule">
          <p class="date">{{ moment(date).format("M월 D일") }}</p>
          <table v-if="scheduleList.length > 0">
            <colgroup>
              <col style="width: 10%" />
              <col style="width: 20%" />
              <col style="width: 20%" />
              <col style="width: 20%" />
              <col style="width: 30%" />
            </colgroup>
            <tr>
              <th>No.</th>
              <th>구분</th>
              <th>고객</th>
              <th>전문가/업체</th>
              <!-- <th>등록일</th> -->
              <th>확정</th>
            </tr>

            <tr v-for="(data, i) in scheduleList" :key="i">
              <td>{{ i + 1 }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'scheduleregister',
                    query: { id: data._id },
                  }"
                >
                  <!-- {{ moment(data.date).format("YYYY.MM.DD ") }} -->
                  {{ data.time }}</router-link
                >
              </td>
              <td>
                <router-link
                  v-if="data.user"
                  :to="{
                    name: 'userDetail',
                    query: { id: data.user._id },
                  }"
                >
                  {{ data.name ? data.name : data.user.username }}
                  <!-- username -->
                </router-link>
                <p v-else>{{ data.name }}</p>
              </td>
              <td>{{ data.provider ? data.provider.providerName : "" }}</td>
              <!-- <td>
                {{ moment(data.created).format("YYYY.MM.DD HH:mm") }}
              </td> -->

              <td>
                <el-tag v-if="data.status == 'confirm'" size="mini"
                  >예약확정</el-tag
                >
                <el-tag
                  v-else-if="data.status == 'cancel'"
                  type="danger"
                  size="mini"
                  >예약취소</el-tag
                >
                <el-tag
                  v-else
                  class="ready"
                  @click="confirm(data._id)"
                  type="warning"
                  size="mini"
                  >확정하기</el-tag
                >
              </td>
            </tr>
          </table>
          <NoData v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  fetchScheduleList,
  fetchProviderList,
  confirmSchedule,
} from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "scheduleManage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      providerList: [],
      allScheduleList: [],
      scheduleList: [],
      provider: "",
      date: moment().format("YYYY-MM-DD"),

      attributes: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "5");
  },
  mounted() {
    this.getProviderList();
    this.getScheduleList();
  },

  methods: {
    handleMove(id) {
      this.$router.push({ name: "providerRegister", query: { id: id } });
    },
    getScheduleList() {
      this.allScheduleList = [];
      this.scheduleList = [];
      this.attributes = [];
      let params = {
        provider: this.provider,
      };
      fetchScheduleList(params).then((res) => {
        if (res.data.status == 200) {
          this.allScheduleList = res.data.data.sort((a, b) => {
            if (a.status === "cancel" && b.status !== "cancel") return 1;
            if (a.status !== "cancel" && b.status === "cancel") return -1;
            return new Date(a.date) - new Date(b.date);
          });
          this.allScheduleList.forEach((item, index) => {
            let title =
              item.time + " " + (item.name ? item.name : item.user.username);
            this.attributes.push({
              key: index,
              customData: {
                title: title,
                status: item.status,
                // class: item.scheduleType,
                id: item._id,
              },
              dates: item.date,
            });
          });
          this.handleDate();
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    handleDate(day) {
      if (day) {
        this.date = day.id;
        window.scrollBy({ top: 200, left: 0, behavior: "smooth" });
      }
      this.scheduleList = this.allScheduleList.filter(
        (item) => moment(item.date).format("YYYY-MM-DD") == this.date
      );
    },
    async confirm(id) {
      await this.$confirm("예약을 확정 하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          let data = {
            scheduleId: id,
          };
          confirmSchedule(data).then((res) => {
            if (res.data.status == 200) {
              this.getScheduleList();
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        })
        .catch(() => {});
    },
    getProviderList() {
      let params = {
        limit: 1000,
      };
      fetchProviderList(params).then((res) => {
        if (res.data.status == 200) {
          this.providerList = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.el-tag.ready {
  cursor: pointer;
}
</style>
